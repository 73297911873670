"use client";

import { useMemo, useState } from "react";
import { Alert, Box, CardMedia, Collapse, Typography } from "@mui/material";

import { LocationVideosQuery, MhcLocationFragment, MhcLocationVideo } from "graphqlApi/types";

import ContinuousLegend from "common/components/GeoMap/ContinuousLegend";
import { legendBoxStyle } from "common/components/GeoMap/Legend/GeoMapLegend";
import { LegendTitle } from "common/components/GeoMap/Legend/LegendTitle";
import { InfoIcon } from "common/components/InfoIcon";

export interface VideoColorConfig {
  value: number;
  rgb: string;
  red: number;
  green: number;
  blue: number;
}

interface Props extends Omit<MhcLocationVideo, "location" | "id"> {
  statIdentifier: LocationVideosQuery["statIdentifier"];
  showValueAlert?: boolean;
  location?: MhcLocationVideo["location"] | MhcLocationFragment;
}

export const TopicVideo = ({
  title,
  stillImageUrl,
  colorConfig,
  statIdentifier,
  showValueAlert = true,
  location
}: Props) => {
  const [legendCollapsed, setLegendCollapsed] = useState(false);
  const legendTitle = statIdentifier?.statCaption || statIdentifier.subtitle;
  const legendProps = useMemo(() => {
    const config: VideoColorConfig[] | undefined | null =
      typeof colorConfig === "string" ? JSON.parse(colorConfig) : colorConfig;
    if (config === undefined || config === null) {
      return null;
    }
    const colorStops = config.filter(({ value }) => value > 0);
    const values = colorStops.map(({ value }) => value);
    const [min, max] = [Math.min(...values), Math.max(...values)];
    const rgbColor = (rgb?: string) => (rgb ? `rgb(${rgb})` : "#CCC");
    return {
      min,
      max,
      colors: [
        rgbColor(config.find(({ value }) => value === max)?.rgb),
        rgbColor(config.find(({ value }) => value === min)?.rgb)
      ],
      noDataColor: rgbColor(config.find(({ value }) => value === -100)?.rgb)
    };
  }, [colorConfig]);

  if (legendProps === null) {
    return (
      <Alert severity="warning">
        <Typography>
          This section is not shown because there are insufficient data for {location?.name}
        </Typography>
      </Alert>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h4">{title}</Typography>
        <InfoIcon variant="dark" sx={{ display: "inline", ml: 1 }}>
          The map shows incident locations based on resident addresses.
        </InfoIcon>
      </Box>
      {stillImageUrl && (
        <Box position="relative" sx={{ backgroundColor: "#b6c6cd" }}>
          <CardMedia
            component="img"
            sx={{
              width: { xs: "60%", lg: "100%" },
              height: { xs: "75vh", lg: "auto" },
              maxHeight: { xs: "500px", lg: "none" }
            }}
            image={`${process.env.NEXT_PUBLIC_PROD_DOMAIN ?? ""}${stillImageUrl}`}
            alt={title ?? "Video still"}
          />
          {legendProps && (
            <Box
              sx={{
                ...legendBoxStyle,
                position: "absolute",
                right: { xs: "5px", md: "10px", lg: "20px" },
                bottom: { xs: "5px", md: "10px", lg: "20px" },
                mr: 0,
                mb: 0
              }}
            >
              {legendTitle && (
                <LegendTitle
                  title={legendTitle}
                  handleCollapse={() => setLegendCollapsed(!legendCollapsed)}
                />
              )}
              <Collapse in={!legendCollapsed}>
                <ContinuousLegend
                  {...legendProps}
                  id={statIdentifier.id}
                  showZeroWatch={true}
                  isPercent={statIdentifier.isPercent}
                />
              </Collapse>
            </Box>
          )}
        </Box>
      )}
      {showValueAlert && (
        <Alert severity="info" sx={{ mt: 2 }}>
          Only counts of 5 or more are used to create maps.
        </Alert>
      )}
    </>
  );
};
