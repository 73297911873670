import { useMemo } from "react";
import dynamic from "next/dynamic";

import { BarChartProps } from "common/components/charts/BarChart";
import { ChartFallback } from "common/components/charts/Chart/Chart";

// dynamic component to get so highcharts resizes correctly
export const DynamicBarChart: React.FC<BarChartProps & { fallback?: JSX.Element }> = ({
  fallback,
  ...props
}) => {
  const Component = useMemo(
    () =>
      dynamic(async () => import("common/components/charts/BarChart"), {
        loading: () => fallback ?? <ChartFallback />,
        ssr: false
      }),
    [fallback]
  );
  return <Component {...props} />;
};
