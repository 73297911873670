import { useMemo } from "react";
import dynamic from "next/dynamic";

import { ChartFallback } from "common/components/charts/Chart/Chart";
import { LineChartProps } from "common/components/charts/LineChart";

// dynamic component to get so highcharts resizes correctly
export const DynamicLineChart: React.FC<LineChartProps> = (props) => {
  const Component = useMemo(
    () =>
      dynamic(async () => import("common/components/charts/LineChart"), {
        loading: () => <ChartFallback />,
        ssr: false
      }),
    []
  );
  return <Component {...props} />;
};
