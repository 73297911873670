import React from "react";
import { Stack, Typography } from "@mui/material";

import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import { MhcAccordion, MhcAccordionProps } from "component/accordion/TextAccordion";
import {
  StatAttributionList,
  StatAttributionListProps
} from "modules/ECDC/components/StatAttributionList";

export type AboutTheDataAccordionProps = {
  id?: string;
  title?: string;
  topicName?: string;
  subtitle?: string;
  attributions?: StatAttributionListProps["attributions"];
  uiLocation?: string;
  showDefaultContent?: boolean;
  children?: React.ReactNode;
  variant?: MhcAccordionProps["variant"];
};
const AboutTheDataAccordion: React.FC<AboutTheDataAccordionProps> = ({
  id,
  title,
  topicName = "COVID-19",
  subtitle = "",
  attributions,
  children,
  uiLocation,
  showDefaultContent,
  variant = "blue"
}) => {
  return (
    <MhcAccordion
      title={
        title ? (
          <Typography fontWeight={700}>{title}</Typography>
        ) : (
          <Typography fontWeight={700}>
            Additional Information
            {topicName && (
              <Typography component="span" fontWeight={400}>
                {" "}
                for {topicName} {subtitle}
              </Typography>
            )}
          </Typography>
        )
      }
      label={`Additional Information for COVID-19 ${subtitle}`}
      variant={variant}
      id={id}
      uiLocation={uiLocation}
    >
      <Stack gap={2.5}>
        {children}
        {!children && showDefaultContent && (
          <ContentCardTextBlock
            title="Ways to interpret the data"
            variant="h5"
            titleProps={{
              fontWeight: 700
            }}
            sx={{ mb: 0 }}
          >
            <Typography>
              When showing percentages, the gray bar represents the % of the total population of the
              selected demographic. The colored bars represent the % of deaths by the selected
              demographic in the state of Delaware.
            </Typography>
            <Typography>
              When showing percentages, certain categories may not appear due to their values being
              zero, which cannot be displayed as a rate.
            </Typography>
          </ContentCardTextBlock>
        )}
        {attributions && attributions.length > 0 && (
          <ContentCardTextBlock
            title="Data Sources"
            variant="h5"
            titleProps={{
              fontWeight: 700
            }}
            component="p"
            sx={{ mb: 0 }}
          >
            <StatAttributionList
              attributions={attributions}
              showDivider={false}
              showTitle={false}
              controlWidth={false}
            />
          </ContentCardTextBlock>
        )}
      </Stack>
    </MhcAccordion>
  );
};

export default AboutTheDataAccordion;
