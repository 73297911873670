import CropFreeOutlined from "@mui/icons-material/CropFreeOutlined";
import { Box, Button, Typography } from "@mui/material";

interface Props {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  date?: string;
  hideEnlargeButton?: boolean;
  titleComponent?: "h3" | "h4" | "h5" | "h6";
  setShowEnlargedChart?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChartWrapper: React.FC<Props> = ({
  children,
  title,
  subtitle,
  date,
  hideEnlargeButton,
  titleComponent = "h5",
  setShowEnlargedChart
}) => {
  const openModal = () => {
    if (setShowEnlargedChart) {
      setShowEnlargedChart(true);
    }
  };

  return (
    <>
      {(title || hideEnlargeButton !== true) && (
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", mb: { xs: 2, md: 0 } }}>
          {title && (
            <Typography variant="h5" component={titleComponent} fontWeight={400} mb={1}>
              {title}
            </Typography>
          )}
          {hideEnlargeButton !== true && (
            <Button sx={{ ml: "auto", p: 0, mr: 0, minWidth: "max-content" }} onClick={openModal}>
              <CropFreeOutlined fontSize="small" sx={{ mr: "5px", my: "auto" }} />
              <Typography variant="caption">Enlarge Chart</Typography>
            </Button>
          )}
        </Box>
      )}
      {(subtitle || date) && (
        <Box display="flex">
          {subtitle && (
            <Typography variant="body2" sx={{ mr: "auto" }}>
              {subtitle}
            </Typography>
          )}
          {date && (
            <Typography variant="body2" sx={{ ml: 2 }}>
              {date}
            </Typography>
          )}
        </Box>
      )}
      {children}
    </>
  );
};
